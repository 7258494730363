<template>
  <div id="app">
    <router-view />
    <popup-loading v-if="loading"></popup-loading>
  </div>
</template>

<script>
import { PopupLoading } from './components/popup'
import { mapState } from 'vuex'
export default {
  components: {
    PopupLoading
  },
  computed: {
    ...mapState(['loading'])
  }
}
</script>

<style lang="less">
@import './assets/css/public.less';
body {
  background: #fff;
  padding: 0;
  margin: 0;
  height: 100vh;
  color: #333333;
}
#app {
  font-size: 28px;
  background-image: url('./assets/imgs/bg.png');
}
.btn {
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #FD6B11;
  font-size: 32px;
  border-radius: 20px;
  font-weight: bold;
}
.transparent-btn {
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  background: rgba(217, 217, 217, 0.5);
  font-size: 32px;
  border-radius: 20px;
  font-weight: bold;
}
.text-primary {
  color: #F68D2E;
}
.text-bold {
  font-weight: bold;
}
</style>
