<template>
  <div :title="i18n.title">
    <div v-if="second>0" class="result-loading-warp">
      <div class="result-loading circle">
        <circle-progress v-model="percent" />
        <span>{{secondFmt}}s</span>
      </div>
      <div class="result-loading-tips">
        {{i18n.msg_tips_loading}}
      </div>
    </div>
    <div v-else-if="order" class="result-content">
      <h2>{{ i18n.title }}</h2>
      <div class="tips">{{ i18n.tips }}</div>
      <img class="mainbanner" :src="imgSrc" alt="">
      <div class="transparent-btn downloadbtn" @click="onDownload"><span>{{ i18nMsg.download }}</span></div>
    </div>
    <result-msg v-else-if="loading" type="100" :text="i18n.msg_result_loading" />
    <result-msg v-else-if="error" type="500" :text="error" @click="onReload" />
    <result-msg v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CircleProgress from '../../components/result/circle_progress.vue'
import ResultMsg from '../../components/result/msg.vue'
import { ORDER_CHECK } from '../../apis/cabinet.js'
import { PAYPAL_EXC_PAY, PAYPAL_CONFIRM, MIDTRANS_PAYCONFIRM } from '../../apis/payment.js'
let loadingTimer
export default {
  components: {
    ResultMsg,
    CircleProgress
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    paypal (val, oldVal) {
      if (val && val.paymentId) {
        this.onPayExec()
      }
    }
  },
  computed: {
    ...mapState(['cabinet', 'system', 'paypalTradeNo', 'paymentId']),
    i18n () {
      return this.$t('result')
    },
    i18nMsg () {
      return this.$t('msg')
    },
    percent () {
      return (this.second % 30) * 3.334
    },
    secondFmt () {
      return (this.second + 0.45).toFixed(0)
    },
    shopDesc () {
      return `${this.i18n.price_info}`.format(this.order)
    },
    infoTips1 () {
      return `${this.i18n.tips_1}`.format(this.order)
    },
    infoTips2 () {
      return this.i18n.tips_2
    },
    imgSrc () {
      // if (this.order && this.order.shebei) {
      //   return require(`../../assets/imgs/cabinet${this.order.shebei}_${this.order.number}.gif`)
      // } else {
      return require(`../../assets/imgs/result_main_banner.png`)
      // }
    }
  },
  data () {
    return {
      loading: false,
      error: '',
      paypal: null,
      order: null,
      tradeNo: '',
      second: 0,
      payMode: 'paypal'
    }
  },
  methods: {
    onDownload () {
      if (this.system === 'ios') {
        this.onAppStore()
      } else {
        this.onGooglePlay()
      }
    },
    onAppStore() {
      console.log('11111')
      if (!process.env.VUE_APP_APP_STORE) {
        this.$toast(this.i18nMsg.appoffline)
        return
      }
      window.location.href = process.env.VUE_APP_APP_STORE
    },
    onGooglePlay() {
      console.log('22222')
      if (!process.env.VUE_APP_GOOGLE_PLAY) {
        this.$toast(this.i18nMsg.appoffline)
        return
      }
      window.location.href = process.env.VUE_APP_GOOGLE_PLAY
    },
    startTimer () {
      if (this.second === 0) {
        loadingTimer = setInterval(() => {
          this.second += 0.1
        }, 100)
      }
    },
    stopTimer () {
      clearInterval(loadingTimer)
      this.second = 0
    },
    onInput (val) {
      this.$emit('input', val)
    },
    goOrder () {
      this.$router.replace('/order')
    },
    onReload () {
      if (this.tradeNo) {
        this.checkOrder(this.tradeNo, this.payMode)
      } else if (this.paypalTradeNo) {
        this.onPaypalConfirm(this.paypalTradeNo)
      } else {
        this.onPayExec()
      }
    },
    onPayExec () {
      this.startTimer()
      this.loading = true
      this.$post(
        PAYPAL_EXC_PAY,
        {
          ...this.paypal
        },
        resp => {
          this.loading = false
          this.error = ''
          const data = resp.data
          if (data.out_trade_no) {
            if (data.pay_type === 'CZ') {
              this.$confirm({
                title: this.i18nMsg.title,
                content: this.i18nMsg.success_payment,
                confirmText: this.i18nMsg.confirm,
                showCancel: false
              }).then(() => {
                this.$router.push('/mine')
              })
            } if (data.pay_type === 'YJ') {
              this.checkOrder(data.out_trade_no)
            }
          }
        },
        error => {
          if (this.second < 30) {
            this.onPayExec()
          } else {
            this.stopTimer()
            this.loading = false
            this.error = error
            console.log(error)
          }
        }
      )
    },
    checkOrder (tradeNo, payMode = 'paypal', payType = '') {
      if (!payType) {
        this.startTimer()
      }
      // if (payMode === 'mpesa') {
      //   this.onSMSCheckOrder(tradeNo, payMode, payType)
      //   return
      // }
      this.tradeNo = tradeNo
      this.loading = true
      this.$post(
        `${ORDER_CHECK}/${tradeNo}/${payMode}`,
        {},
        resp => {
          if (payType === 'FK') {
            this.$router.replace({ path: '/order' })
          } else if (payType === 'CZ') {
            this.$router.replace({ path: '/wallet' })
          }
          this.loading = false
          this.error = ''
          this.stopTimer()
          this.order = resp
        },
        error => {
          if (payType === 'FK') {
            setTimeout(() => {
              this.$router.replace({ path: '/order' })
            }, 1500)
          } else if (payType === 'CZ') {
            setTimeout(() => {
              this.$router.replace({ path: '/wallet' })
            }, 1500)
          } else if (error === '正在请求中' || error === 'Processing') {
            setTimeout(() => {
              this.checkOrder(tradeNo, payMode, payType)
            }, 1000)
          } else {
            this.stopTimer()
            this.loading = false
            this.error = error
            console.log(error)
          }
        }
      )
    },
    onPaypalConfirm(tradeNo) {
      this.startTimer()
      this.loading = true
      let url = PAYPAL_CONFIRM
      this.$post(
        url,
        {
          tradeNo: tradeNo,
          paymentId: this.paymentId
        },
        resp => {
          this.loading = false
          this.error = ''
          this.stopTimer()
          this.order = resp
        },
        error => {
          if (error === '正在请求中' || error === 'Processing') {
            setTimeout(() => {
              this.onPaypalConfirm(tradeNo)
            }, 1000)
          } else {
            this.stopTimer()
            this.loading = false
            this.error = error
            console.log(error)
          }
        }
      )
    },
    onMidtransConfirm(tradeNo) {
      this.loading = true
      this.tradeNo = tradeNo
      let url = MIDTRANS_PAYCONFIRM
      this.$get(
        url,
        {
          outTradeNo: tradeNo
        },
        resp => {
          this.checkOrder(tradeNo, 'Midtrans')
        },
        error => {
          if (error === '正在请求中' || error === 'Processing') {
            setTimeout(() => {
              this.onMidtransConfirm(tradeNo)
            }, 1000)
          } else {
            this.stopTimer()
            this.loading = false
            this.error = error
            console.log(error)
          }
        }
      )
    }
  },
  created () {
    console.log('result route:', this.$route.query)
    let { paymentId, token, PayerID, outTradeNo, tradeNo, payMode, payType } = this.$route.query
    this.payMode = payMode
    if (this.paypalTradeNo) {
      this.onPaypalConfirm(this.paypalTradeNo)
    } else if (outTradeNo) {
      this.onMidtransConfirm(outTradeNo)
    } else if (tradeNo) {
      this.checkOrder(tradeNo, payMode, payType)
    } else {
      this.paypal = {
        paymentId,
        token,
        payerId: PayerID
      }
    }
  },
  beforeDestroy() {
    this.$store.commit('paypalTradeNo', null)
  },
  destroyed () {
    this.$store.commit('cabinet', null)
  }
}
</script>

<style lang="less">
.result-content{
  min-height: 100vh;
  .unlocked{
    max-width: 30%;
    display: block;
    margin: 0px auto;
    padding: 50px 0 0;
  }
  h2{
    font-size: 52px;
    font-family: 'Montserrat-SemiBold';
    font-weight: bold;
    text-align: center;
    margin: 0;
    padding: 40px 0;
  }
  .mainbanner{
    max-width: 60%;
    display: block;
    margin: 0 auto 60px;
  }
  .tips{
    font-size: 30px;
    color: #000;
    text-align: center;
    margin: 20px 40px 40px;
  }
  .downloadbtn{
    width: 80%;
    margin: 0 auto;
    font-weight: bold;
    text-align: center;
    border-radius: 20px;
  }
}
.result-loading{
  &.circle{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-warp{
    background: #FFFFFF;
    padding: 30px 0;
  }
  & span{
    position: absolute;
    font-size: 70px;
    color: #F13453;
  }
  &-tips{
    text-align: center;
    font-size: 28px;
    color: #1bb6e6;
  }
}
</style>
