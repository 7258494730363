<template>
  <div class="menu-side">
    <div class="menu-side-title">
      <img src="../../assets/imgs/avatar.png" alt="">
      <span v-if="user && user.pname">{{user.pname | decode}}</span>
      <span v-else @click="goRouter('/login')">{{i18n.login_tips}}</span>
    </div>
    <!-- <div class="menu-side-row" @click="goRouter('/wallet')">
      <img src="../../assets/imgs/wallet.png" alt="">
      <span>{{i18n.wallet}}</span>
    </div> -->
    <div class="menu-side-row" @click="goRouter('/order')">
      <img src="../../assets/imgs/icon_order.png" alt="">
      <span>{{i18n.order}}</span>
    </div>
    <!-- <div class="menu-side-row">
      <img src="../../assets/imgs/coupon.png" alt="">
      <span>优惠券</span>
    </div> -->
    <div class="menu-side-row" @click="goRouter('/setting')">
      <img src="../../assets/imgs/setting.png" alt="">
      <span>{{i18n.setting}}</span>
    </div>
    <div class="menu-side-row" @click="goRouter('/help')">
      <img src="../../assets/imgs/icon_help.png" alt="">
      <span>{{i18n.help}}</span>
    </div>
    <div class="menu-side-row" @click="onLogout">
      <img src="../../assets/imgs/logout.png" alt="">
      <span>{{i18n.logout}}</span>
    </div>
    <!-- <div class="menu-side-foot" @click="goWebsite">
      <span class="text-primary text-bold">{{ i18n.menutip1 }}</span>
      <span>{{ i18n.menutip2 }}</span>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['user']),
    i18n () {
      return this.$t('mine')
    }
  },
  methods: {
    onLogout () {
      this.$store.commit('user', null)
      this.$store.commit('token', '')
      this.$router.push('/login')
    },
    goRouter (path) {
      this.$router.push(path)
    },
    goWebsite () {
      window.open('https://www.ezcharger.com')
    }
  }
}
</script>

<style lang="less">
.menu-side {
  width: 550px;
  &-title {
    font-size: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 80px 0 48px;
    background-color: #FD6B11;
    margin-bottom: 20px;
    img {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      margin: 0 24px 0 48px;
    }
  }
  &-row {
    font-size: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 80px;
    margin-left: 20px;
    font-weight: bold;
    padding: 10px 0;
    img {
      width: 60px;
      height: 60px;
      margin: 0 20px 0 28px;
    }
  }
  &-foot {
    width: 75%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 48px;
    color: #fff;
    background: #000;
    border-radius: 20px;
    padding: 20px;
    span{
      display: block;
    }
  }
}
</style>
